<script setup>
defineProps({
  image: {
    type: Object,
    default: null,
  },
  lazyLoad: Boolean,
})
</script>

<template>
  <div class="slide-picture">
    <picture>
      <img
        :src="image.custom"
        alt="DigitXS"
        :loading="lazyLoad ? 'lazy' : 'auto'"
      />
    </picture>
  </div>
</template>

<script>
export default {
  name: 'SwiperPicture',
}
</script>

<style lang="scss">
@import './style.scss';
</style>
